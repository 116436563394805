@tailwind base;
@tailwind components;
@tailwind utilities;

@import "font";
@import "color";

* {
  font-family: "Roboto", sans-serif;
}

body {
  background-color: $base-color;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
